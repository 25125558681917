<template>
    <section id="Home" class="section">
   
   <div class="read-more-img container-fluid" v-for="object in HomeImgObj" v-bind:key="object.key">
     <img :src=object.img>
   </div>
   <div class="read-more-content" v-for="object in HomeTitleObj" v-bind:key="object.key">
     <p class="read-more-text">{{object.title}}</p>
   </div>

   <div class="vm-container">

     <div  class="container read-more-row" >
     <div class="row">
       <div class="col-lg-6 col-md-12 col-sm-12 vision-mission" v-for="object in visionMisionObj" v-bind:key="object.id">
         <img :src=object.img :class=object.imgClass>
         <div :class=object.class>
           <h2 :class=object.titleClass>{{object.title}} </h2>
           <p :class=object.subtitleClass>{{object.subtitle}}
           </p>
           <a href="" class="vision-link">{{object.link}}</a>
         </div>
       </div>
     </div>
     </div>
    </div>
   </section>
</template>
<script>


export default {
    data(){
        return{
            HomeImgObj:[{
            img: require("../assets/img/home/home-img.png")
            }],
            HomeTitleObj:[{
            title:"is one as simple as a need unmet throughout our years of expertise where we witnessed successful creative medical communications, and successful medical educational e orts, with little to nothing in the region connecting both to create a strategic seamless creative."
            }],
            visionMisionObj:[{
                class:"vision",
                imgClass:"vision-img",
                img: require("../assets/img/home/vision-img.png"),
                titleClass:"vision-header",
                title:"Vision",
                subtitleClass:"vision-text",
                subtitle:"we ultimately aim to empower the healthcare industry bridging gaps in medical communication & education through integrating ... ",
                link:"Read more"
            },
            {
                class:"mision",
                imgClass:"mision-img",
                img: require("../assets/img/home/mission-img.png"),
                titleClass:"mision-header",
                title:"Mission",
                subtitleClass:"mision-text",
                subtitle:"Enhancing medical communication & education with creative approaches & strategy through meticulous connectins...",
                link:"Read more"
            }
        ]
        }
    }
}
</script>
<style scoped>

#Home{
    padding: 3rem 0 1rem;
}
.vm-container{
    text-align: center;
}
.vision-mission{
    margin-bottom: -6rem;
}
.vision-img, .mision-img{
    position: relative;
    z-index: 2;
}
.vision, .mision{
    width: 339px;
    height: 172px;

    background: #FFFFFF;
    box-shadow: 4px 24px 60px rgba(109, 141, 173, 0.25);
    border-radius: 4px;
    padding: 1.5rem;
    z-index: 3;
    position: relative;
    left: 180px;
    top: -150px;
}

.vision-header, .mision-header{
    width: 92px;
    height: 40px;

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 42px;
  

    color: #000000;
}

.vision-text, .mision-text{
    
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;

    color: #484848;
}
.vision-link, .mision-link{
   
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;

    color: #A3CB3A;
}
.vision .vision-link:hover{
    color: #807E7E;
}
.mision .vision-link:hover{
    color: #807E7E;
}
.read-more-content{
    display: flex;
    align-items: center;
    justify-content: center;
    
}

.read-more-img{
    text-align: center;
    
}
.read-more-content{
    margin-bottom: 2rem;
}
.read-more-text{

   font-family: 'Roboto';
   font-style: normal;
   font-weight: 400;
   font-size: 18px;
   line-height: 22px;

   color: #999999;

   width: 567px;
   height: 88px;
   text-align: center;
   margin-top: 3rem;
   margin-bottom: 2rem;

} 

</style>

