<template>
    <section id="Values" class="section">
        <div class="values-head" v-for="object in valueHeadObj" v-bind:key="object.key">
        <h1 class="values-header">{{object.title}}</h1>
       <div class="line-container">
       <div class="values-line"></div>
       </div>
      </div>
      <div class="values-content">
        <p class="values-subheader">Our core values emanates from our belief that being meticulous
         about every detail is fundamental to bridging the gaps in medical
         communications & education and ultimately the healthcare
         industry.</p>
      </div>
      
      <div>
      <div class="container">
        <div class="row">
         <div class="col-lg-4 col-md-6 col-sm-6" v-for="object in valueContentObj" v-bind:key="object.key">
          <img :src=object.img class="val-item-img">
          <h2 class="val-item-header">{{object.title}}</h2>
          <p class="val-item-subheader">{{object.subtitle}}</p>
         </div>
        </div> 
      </div>
    </div>
      </section>
</template>

<script>


export default {
    data(){
        return{
            valueHeadObj:[{
                title:"OUR Values",
            }],
            valueSubheadObj: [{
                subtitle:"Our core values emanates from our belief that being meticulous about every detail is fundamental to bridging the gaps in medical communications & education and ultimately the healthcare industry."
            }],
            valueContentObj: [{
                img:require("../assets/img/values/health.svg"),
                title: "Health",
                subtitle: "our first and utmost i viture s patients health and every detail that can even remotely improve the quality of care they recieve."
            },
            {
                img:require("../assets/img/values/passion.svg"),
                title: "Passion",
                subtitle: "Our passion goes beyond our love for what  we  do it is extended to the way we chose to contribute to improving clinical practice."
            },
            {
                img:require("../assets/img/values/knowledge.svg"),
                title: "Knowledge",
                subtitle: "our regional experties & knoledge paired with our belief in the role of knowledge are core to providing medical care."
            },
            {
                img:require("../assets/img/values/divirsity.svg"),
                title: "Divirsity",
                subtitle: "Our efforts are driven by the diversity of needs in the industry and we ensure attending to diversity by connecting every detail of medical."
            },
            {
                img:require("../assets/img/values/empowerment.svg"),
                title: "Empowerment",
                subtitle: "Our power is fueled with ensuring every detail in medical communication & education empowers HCPS and ultimately quality of care provided."
            },
        ]

        }
    }
}
</script>

<style scoped>

.values-content, .services-content, .read-more-content, .subscribe, .line-container{
    display: flex;
    align-items: center;
    justify-content: center;
    
}

.values-head, .services-head{
    margin: 1rem;
    padding: 1rem;
    text-align: center;
}

.values-header{
   
    font-family: 'Arial-Rounded-MT-Bold-Font';
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 37px;
    
    margin-bottom: 0px;
    color: #262262;

}

.values-line{
   

    width: 52.36px;
    height: 0px;
    border-bottom: 4px solid #55BCBC;
    border-radius: 2px;
    margin-top: 0.5rem;
    text-align: center;
}

.values-subheader{
   width: 459px;
   height: 76px;
   font-family:'Roboto';
   font-style: normal;
   font-weight: 400;
   font-size: 16px;
   line-height: 19px;

   color: #999999;
   text-align: center;
   padding: 0.5rem;

}
.val-item-img{
    display: block;
    margin-left: auto;
    margin-right: auto;
    height: 55px;
}
.val-item-header{

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 42px;
    
    color: #000000;
    text-align: center;

}
.val-item-subheader{

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;

    color: #5F5F5F;
    text-align: center;
}
#Values{
    padding: 2rem 0 2rem;
    background: #F9F9F9;
}

.container{
    margin-top:2rem ;
}
</style>