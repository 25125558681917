<template>
    <section id="Personality" class="section">
        <div class="head">
        <div class="personality-head" v-for="object in personalityHeadObj" v-bind:key="object.id">
        <h1 class="personality-header">{{object.title}}</h1>
        <div class="line-container">
          <div class="personality-line"></div>
        </div>
      </div>
      <div class="personality-content" v-for="object in personalTextObj" v-bind:key="object.key">
        <p class="personality-subheader">{{object.subheader}}</p>
      </div>

      <div class="container personality-row">
        <div class="row personality-container">
         <div class="col-lg-4 col-md-6 col-sm-12 d-flex justify-content-center justify-content-md-center" v-for="object in boxObj" v-bind:key="object.id">
          <div class="box">
            <div class="innerbox">
              <img :src=object.img class="box-img">
            </div>
            <div class="box-content">
            <h2 class="box-header">{{object.title}}</h2>
            <p class="box-subheader">{{object.subtitle}}</p>
          </div>
          </div>
         </div>
        </div>
      </div>
    </div>
      </section>
</template>

<script>
    export default{
        data(){
            return{
                personalityHeadObj:[{
                    title:"OUR PERSONALITY",
                }],
                personalTextObj: [{
                    subheader:"Our personality expression principles emanate directly from our values; they define the essence of Medicalous Communications."
                }],
                boxObj:[{
                    img:require("../assets/img/personality/supportive.png"),
                    title:"SUPPORTIVE",
                    subtitle:"We support the health of our people and support healthcare professionals with the supporting knowledge."
                },
                {
                    img:require("../assets/img/personality/creative.png"),
                    title:"CREATIVE",
                    subtitle:"we belive that for knowledge to resonate and create an impact it needs to be innovative & creative."
                },
                {
                    img:require("../assets/img/personality/engaged.png"),
                    title:"ENGAGED",
                    subtitle:"In how we will connect every detail of knowledge and deliver in an engaging manner."
                },

            ]
            }
        }
    }
</script>

<style scoped>

#Personality{
    
    background-image: url(../assets/img/personality/personality-min.jpg);
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: center center;
    background-size: cover;

}
.head {
  
    backdrop-filter: brightness(80%);
    padding: 2rem 0 2rem;
    
} 
.personality-head{
    padding: 2rem;
}
.personality-header{
    font-family: 'Arial-Rounded-MT-Bold-Font';
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 26px;
    text-align: center;

    color: #FFFFFF;
    
}
.personality-line{
    
    width: 52.36px;
    height: 0px;
    border: 3px solid #FFFFFF;
    border-radius: 3px;
    background: #FFFFFF;
    margin-top: 0.5rem;
  
}
.line-container{
    display: flex;
    align-items: center;
    justify-content: center;
    
}

.personality-content{
    display: flex;
    align-items: center;
    justify-content: center;
}
.personality-subheader{
    width: 459px;
    height: 76px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;

    color: #EBEBEB;
    text-align: center !important;

}

.box{
    
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 25px 17px;
    width: 238.5px;
    height: 314px;
    border: 1px solid #FFFFFF;
    border-radius: 17px;
    background: transparent;
    margin-bottom: 4rem;
}
.innerbox{
    width: 106.8px;
    height: 106.8px;
    left: 65.84px;
    top: 25.63px;

    border: 1.06799px solid #FFFFFF;
    border-radius: 8.54395px;
    background: transparent;
    padding: 1rem;
    margin-bottom: 2rem;
    
}
.box-img{
    height: 74px;
    width: 74px;
}
.box-header{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 43px;

    display: flex;
    align-items: left;

    color: #FFFFFF;
}

.box-subheader{

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    
    color: #FFFFFF;
}

.personality-row{
    display: flex;
    align-items: center;
    justify-content: center;
}

</style>