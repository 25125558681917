<template>
    <nav class="navbar navbar-expand-lg" id="nav">
        <div class="container-fluid" v-for="object in logoObj" v-bind:key="object.id">
          <img :src=object.img class="logo-img">
            <button type="button" class="navbar-toggler" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarCollapse" >
                <ul class="navbar-nav text-uppercase" v-for="object in navObj" v-bind:key="object.id">
                  <li class="nav-item active">
                    <a class="nav-link smooth-link" :href=object.link>{{object.title}}</a>
                  </li>
                </ul>
                <div class="navbar-nav ms-auto" v-for="object in subnavObj" v-bind:key="object.id">
                  <img :src=object.img class="tel-img">
                  <p class="tel nav-item">{{object.tel}}</p>
                </div>
                </div>
                
            </div>
      </nav>
   
</template>

<script>

export default {
    data(){
        return{
           logoObj:[{
            img: require("../assets/img/logo/Frame-img.png")
            }],
            navObj:[{
                link:"#Home",
                title:"Home"

            },
            {
                link:"#Values",
                title:"Values"

            },
            {
                link:"#Personality",
                title:"Personality"

            },
            {
                link:"#Services",
                title:"Services"

            },
            {
                link:"#Contact",
                title:"Contact"

            }],
            subnavObj:[{
                img:require("../assets/img/logo/tel.png"),
                tel:"+2349067322844"
            }]
        }
    }

}
</script>

<style scoped>
  
.navbar{
    background: rgba(4, 52, 96, 0.63);
}

.logo-img{
    width: 138px;
    height: 32px;
    margin-left: 2rem;
}
.navbar-nav{
    margin-left: 2rem;
}
.navbar-nav .nav-item .nav-link{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #FFFFFF;
}
.navbar-nav .tel{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 15px;

    color: #FFFFFF;
}
.navbar-nav .nav-item :hover{
    
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    text-decoration-line: underline;

    color: #A3CB3A;

}
.tel-img{
    width: 16.5px;
    Height: 16.5px; 
    margin: 16px;
}
.tel{
    margin-top: 16px;
} 

</style>
