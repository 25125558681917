<template>
    <div>
     <div id="Contact" class="section contact-container">
      <div class="container">

       <div class="row">
        <div class="col-2" v-for="object in contactObj" v-bind:key="object.id">
          <img :src=object.img class="contact-item-img img-fluid">
        </div>
       </div>
      </div>
</div>
<div class="subscribe">
  <div class="container">
      <div class="row">
        <div class="col-lg-6 col-md-12 col-sm-12">
          <p class="upper-case font-weight-bold sub-text">GET MORE UPDATE TO SUBUSCRIBE US </p>
        </div>
        <div class="col-lg-6 col-sm-12">
          <div class="input-group submision-form">
        <input type="text" class="sub-form" placeholder="User@gmail.com" aria-label="username">
        <button class="sub-btn " type="button">Subscribe</button>
        </div>
      </div>
    </div>
  </div>
  
</div>
<div>
  <div class="container form-container">
    <div class="row">
    <div class="col-lg-6 col-md-6 col-sm-12">
      <h2 class="touch">Get in touch</h2>
      <form>
        <div class="form-group">
          <input type="text" class="regist-form" id="name" placeholder="Name">
          <input type="email" class="regist-form" id="email" placeholder="Email">
          <input type="text" class=" regist-form" id="tel" placeholder="Mobile number">
          <textarea class="regist-text" id="message" placeholder="Your Message"></textarea>
          <button class="regist-btn" type="submit" value="submit">Send</button>
  </div>
  </form>
    </div>
    <div class="col-lg-6 col-sm-12">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <h2 class="touch">Find us</h2>
            <div class="mapouter">
              <div class="gmap_canvas">
                <iframe width="100%" height="260" id="gmap_canvas" src="https://maps.google.com/maps?q=2880%20Broadway,%20New%20York&t=&z=13&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0">

                </iframe></div></div></div>
          <div class="col-12">
            <h2 class="touch">Contact us</h2>
            <div>
              <div class="address" v-for="object in contactUsObj" v-bind:key="object.id">
              <img :src=object.icon class="contact-img">
              <span class="contact-info">{{object.title}}</span>
              </div>
            </div>
            
          </div>
        </div>
      </div>
    </div>
    </div>
    </div>
</div>
</div>
</template>
<script>


export default {
    data(){
        return{
            contactObj:[{
                img:require('../assets/img/contact/1.svg')
            },
            {
                img:require('../assets/img/contact/2.svg')
            },
            {
                img:require('../assets/img/contact/3.svg')
            },
            {
                img:require('../assets/img/contact/4.svg')
            },
            {
                img:require('../assets/img/contact/5.svg')
            },
            {
                img:require('../assets/img/contact/6.svg')
            }],
            contactUsObj:[{
                icon:require('../assets/img/contact/location.svg'),
                title:"3909 Abdullhamid Alkatib ST, Azahrah Dist."
            },
            {
                icon:require('../assets/img/contact/telephone.svg'),
                title:"+966 123 456 7890"
            },
            {
                icon:require('../assets/img/contact/email.svg'),
                title:"Support@medic.com"
            }
        ]
            

        }
    }
}
</script>
<style scoped>

.subscribe{
    display: flex;
    align-items: center;
    justify-content: center;

    /* width: 88vw;
    margin-left: 5.5vw; */
    
}
.contact-container{
    height: 126px;
    padding-top: 2rem;

    background: #F4F9FD;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);

    /* width: 88vw;
    margin-left: 5.5vw; */

}

.subscribe{
    height: 196px;

    background: #F3F6F9;
    border-radius: 2px;
}

.submision-form{
  margin-top: 2rem;
}
.sub-text{

    font-family: 'Arial-Rounded-MT-Bold-Font';
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    line-height: 46px;

    color: #043460;

}

.sub-btn{
    padding: 12px 16px;


    width: 133px;
    height: 65px;

    background: #57C9C6;
    border: 1px solid #57C9C6;
    border-radius: 5px;

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #FFFFFF;
}

.sub-form{
    width: 300px;
    height: 55px;

    background: #FFFFFF;
    border: 1px solid #E6E6E6;
    border-radius: 4px;
    padding: 1rem;
    
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;


    color: #252525;
}
.sub-btn {
  height: 55px;
}
.form-container{
    margin-top: 2rem;
}
.touch-form{
    margin: 2rem;
}
.touch{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;

    margin-bottom: 2rem;


color: #000000;
}

.regist-form{
    width: 500px;
    height: 56px;
    margin-bottom: 2rem;


   background: #F5F5F5;
   border: 1px solid #F5F5F5;
   border-radius: 8px;


   padding: 16px 24px;
   font-family: 'Roboto';
   font-style: normal;
   font-weight: 400;
   font-size: 18px;
   line-height: 24px;
   color: #9E9E9E;

}

.regist-text{
    padding: 16px 24px;
    width: 500px;
    height: 126px;


    background: #F5F5F5;
    border: 1px solid #F5F5F5;
    border-radius: 8px;

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 2rem;
}

.regist-btn{
    padding: 16px 40px;

    width: 117px;
    height: 51px;

    background: #57C9C6;
    border: 1px solid #57C9C6;
    border-radius: 5px;

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-align: center;

    color: #FFFFFF;
    margin-bottom: 2rem;

}
.contact-info{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;


    margin-left: 1rem;
    color: #000000;
}

.address{
    margin-bottom: 1rem;
}

/* .map-img{
    width: 560px;
    height: 260px;
    left: 0px;
    top: 40px;
    margin-bottom: 2rem;
    border: 1px solid #C5C5C5;
    border-radius: 6px;
} */

.mapouter{
  position:relative;
  text-align:right;
  max-height:260px;
  max-width:560px;
  margin-bottom: 2rem;
  border: 1px solid #C5C5C5;
  border-radius: 6px;
}
</style>