<template>
    
    <header class="header">
      <!-- <div class="background-image"></div> -->
      <div class="head container-fluid">
      <div class="container" v-for="object in headerObj" v-bind:key="object.key">
       <div class="row">
        <div class="col-lg-6 col-sm-12" >
        <h1 class="header-heading upper-case">{{object.title}}</h1>
   
        <h2 class="header-subheading">{{object.subtitle}}</h2>  
    </div>
    </div>
    <div class="button-group">
        <button class="read-btn">{{object.readMoreBtn}}</button>
        <button class="contact-btn">{{object.ContactUsBtn}}</button>
    </div>
</div>
</div>
    </header>
</template>

<script>


export default {
    data(){
        return{
            headerObj:[{
                title:"OUR STROY WHERE SCIENCE MEETS CREATIVITY AMETICULOUS CONNECTION BRINGING GAPS",
                subtitle:"medical commuication & education in the healthcare indusstry. our story is one as simple as aneed un met throughot out years of expertise where we witnessed successful creative",
                readMoreBtn:"Read more",
                ContactUsBtn:"Contact us"
            }]

        }
    }
    
}
</script>
<style scoped>


    .header {
        
        background-image: url(../assets/img/header/header-img-min.jpg);
        background-repeat: no-repeat;
        background-attachment: scroll;
        background-position: center center;
        background-size: cover;
        
        
    }
    .head{
        
        backdrop-filter: brightness(80%);
        padding-top: 6.5rem;
        height: 90vh;
    }


.header-heading{
    
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 42px;
    color: #FFFFFF;
    margin-left: 3rem;
}

.header-subheading{
   
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;

    color: #FFFFFF;
    margin-left: 3rem;
}



.read-btn{
    
    width: 136px;
    height: 45px;
    margin: 24px;
    background-color: #57C9C6;
    border: 2px solid #57C9C6;
    border-radius: 4px;

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;

    color: #FFFFFF;
    
    margin-left: 3rem;
    margin-bottom: 9rem;
}
.contact-btn{
   
    width: 136px;
    height: 45px;
    margin: 24px;
    background: rgba(0, 0, 0, 0.19);
    border: 2px solid #FFFFFF;
    border-radius: 4px;
    
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;

    color: #FFFFFF;
}

.button-group{
    display: flex;
    align-items: flex-start;
}
</style>