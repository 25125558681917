<template>
    <section id="Services" class="section container-fluid">
        <div class="services-head" v-for="object in  serviceHeadObj" v-bind:key="object.id">
        <h1 class="services-header">{{object.title}}</h1>
        <div class="line-container">
       <div class="services-line"></div>
       </div>
      </div>
      <div class="services-content" v-for="object in serviceTextObj" v-bind:key="object.id">
        <p class="services-subheader">{{object.subheader}}</p>
      </div>
      
      <div class="container">
       <div class="row">
        <div class="col-lg-3 col-md-6 col-sm-6 d-flex justify-content-center justify-content-md-center" v-for="object in serviceContentObj" v-bind:key="object.key">
          <div class="card">
            <div class="card-img">
              <img :src=object.img>
            </div>
          <h2 class="card-title">{{object.title}}</h2>
          <p class="card-subtitle">{{object.subtitle}}</p>
          </div>
        </div>
      </div>
       </div>
      </section>
</template>

<script>


export default {
    data(){
        return{
            serviceHeadObj:[{
                title:"OUR SERVECIES",
            }],
            serviceTextObj:[{
                subheader:"We enable and provide accessibility to strategic seamless creative educational communication experience. Our services are specifically tailored to bridge gaps in medical communication & education in the healthcare industry through integrating knowledge, creativity, strategy, & science with all their intricate details to build a holistic experience."
            }],
            serviceContentObj:[{
                img:require('../assets/img/services/medical-plateform.svg'),
                title:"Medical Platform Development",
                subtitle:"Developing engaging platforms that provide healthcare professionals with amultitude of opportunites from learning, expertises"
            },
            {
                img:require('../assets/img/services/communication.svg'),
                title:"Communication Planning",
                subtitle:"The existance of science, data & information is crirical vut turning it into strategic communication is what differentiates"
            },
            {
                img:require('../assets/img/services/narrative.svg'),
                title:"Scientific Narrative Development",
                subtitle:"Turning scientific data into astory is one of the first basic steps when engaging with health care professional"
            },
            {
                img:require('../assets/img/services/publication.svg'),
                title:"Puplication Planning",
                subtitle:"At mesicalouse we are experts who have extensive knowledge of every detail of pupblication activities,from developing"
            },
            {
                img:require('../assets/img/services/expert.svg'),
                title:"Expert Engagement",
                subtitle:"Engagine kols is acritical element while strategizing every product,we at medicalous are. experts at identifying the every detail"
            },
            {
                img:require('../assets/img/services/medical-education.svg'),
                title:"Medical Education",
                subtitle:"A key to effective communication is meeting health care professionals need a that is our first stepping-stone when working "
            },
            {
                img:require('../assets/img/services/event.svg'),
                title:"Events & Congress Suppport",
                subtitle:"At medicalous we believe that engaging health care professionals is a key steps in every product journey with our exepertise "
            },
            {
                img:require('../assets/img/services/multichannel.svg'),
                title:"Multichannel Planning",
                subtitle:"In a digitalized fast-paced world it has become anecessity to communicate with your health care professionals through flexible channels"
            },
            {
                img:require('../assets/img/services/msi.svg'),
                title:"Msl Support",
                subtitle:"Our expert team ensures that we provide all the latest research & new developments as per area of experise to strengthen "
            },
            {
                img:require('../assets/img/services/medical-writing.svg'),
                title:"Scientific Medical Writing",
                subtitle:"We have adedicated medical writing team that study your date with meticulous interpretations to communicate to you "
            },
            {
                img:require('../assets/img/services/medical-info.svg'),
                title:"Medical Information",
                subtitle:"our research capabilities have no bound with the expertise of our medical researchers their meticulous eye for details"
            },
            {
                img:require('../assets/img/services/HEOR.svg'),
                title:"HEOR communications planning",
                subtitle:"Hero is essential for products at medicalous we recognize its importance and have dedicated a team to support"
            },
        ]
        }
    }
}
</script>
<style scoped>

#Services{
    padding: 2rem 0 2rem;
}
.services-header{

font-family: 'Arial-Rounded-MT-Bold-Font';
font-style: normal;
font-weight: 400;
font-size: 32px;
line-height: 37px;

text-align: center;
margin-bottom: 0.5rem;

color: #043460;

}

.line-container, .services-content{
    display: flex;
    align-items: center;
    justify-content: center;
    
}

.services-line{
    
    width: 76px;
    height: 0px;
    border-bottom: 4px solid #55BCBC;
    border-radius: 2px;
    
    margin-top: 0rem;
    text-align: center;
}

.services-head{
    margin: 1rem;
    padding: 1rem;
    text-align: center;
}
.services-subheader{
    
    height: 126px;
    width: 521px;

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    text-align: center !important;

    color: #9F9E9E;
    margin-bottom: 5rem;
    
}

.card{
    background: #FFFFFF;
    width: 270px;
    height: 278px;
    padding: 2rem;
    border: 1px solid #E0E0E0;
    border-radius: 5px;
    margin-bottom: 2rem;
    margin-left: 0px;

}

.card-img{
    width: 70px;
    height: 70px;
    margin-bottom: 1rem;
    display: flex;
    align-items: flex-start;
    

}
.card-title{
    
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color:#000000;
    

}

.card-subtitle{

    height: 64px;

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;

   color: #757575;
}


</style>