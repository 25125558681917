<template>
    <div class="footer container-fluid">
  <div class="footer-container">
      <div class="d-flex justify-content-center copy-rights">
        <p class="rights"> &copy; 2022 MEDICALOUS Healthcare | All right reseved</p>
      <div v-for="object in footerObj" v-bind:key="object.id"> 
        <img :src=object.img class="social-media">
      </div>
    </div>
  </div>
 </div>
</template>

<script>


export default {
    data(){
        return{
            footerObj:[{
                img: require('../assets/img/footer/facebook.svg')
            },
            {
                img: require('../assets/img/footer/instagram.svg')
            },
            {
                img: require('../assets/img/footer/twitter.svg')
            },
        ]}
    }
}

</script>

<style scoped>

.footer{
    background: #FAFAFA;
    height: 60px;
}
.copy-rights{
   padding-top: 30px;

}
.rights{
    width: 317px;
    height: 16px;

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    text-align: center;

    color: #878686;
}

.social-media{
    padding-left: 30px; 
    position: relative;
    bottom: 0.5rem;  
}

</style>