import { render, staticRenderFns } from "./ServiceSec.vue?vue&type=template&id=60829920&scoped=true&"
import script from "./ServiceSec.vue?vue&type=script&lang=js&"
export * from "./ServiceSec.vue?vue&type=script&lang=js&"
import style0 from "./ServiceSec.vue?vue&type=style&index=0&id=60829920&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "60829920",
  null
  
)

export default component.exports