<template>
  <div id="app">
    <!-- NAVBAR -->
    <NavSec></NavSec>
 
    <!-- HEADER -->
    <HeaderSec></HeaderSec>
    <!-- Home -->
    <HomeSec></HomeSec>
    <!-- VALUES -->
    <ValueSec></ValueSec>  

    <!-- PERSONALITY -->
    <PersonalitySec></PersonalitySec>

    <!-- SERVICES -->
    <ServiceSec></ServiceSec>

    <!-- CONTACT US -->
    <ContactSec></ContactSec>

<!-- FOOTER -->

 <FooterSec></FooterSec>
  
  </div>
</template>

<script>
import NavSec from './components/NavSec.vue';
import HeaderSec from './components/HeaderSec.vue';
import HomeSec from './components/HomeSec.vue';
import ValueSec from './components/ValueSec.vue';
import PersonalitySec from './components/PersonalitySec.vue';
import ServiceSec from './components/ServiceSec.vue';
import FooterSec from './components/FooterSec.vue';
import ContactSec from './components/ContactSec.vue';


export default {
    name: "App",
    components: { NavSec, HeaderSec, HomeSec, ValueSec, PersonalitySec, ServiceSec, FooterSec, ContactSec }
}
</script>

<style>

@import './assets/css/style.css';
@font-face {
  font-family:"Arial-Rounded-MT-Bold-Font";
  src: url("./assets/font/Arial-Rounded-MT-Bold-Font/ARLRDBD.ttf");
}
@font-face {
  font-family:"Roboto";
  src: url("./assets/font/roboto/Roboto-Regular.ttf");
}
@font-face {
  font-family:"Poppins";
  src: url("./assets/font/Poppins/Poppins-Regular.ttf");
  }
</style>
